<template>
  <div>
    <template v-for="(item,index) in hotspots">
      <template v-if="item.guid==editHotspotGuid">
        <div class="baseArea">
          <template v-if="showRange==null">
            <div style="display:flex">
              <div style="flex:auto">
                <div title="扭曲" class="custom-control custom-switch switch ppitem">
                  <input type="checkbox" class="custom-control-input" id="distorted" @change="setHotspot(item)" v-model.trim="item.distorted">
                  <label class="custom-control-label" for="distorted">扭曲</label>
                </div>
                <div title="显示名称" class="custom-control custom-switch switch ppitem">
                  <input type="checkbox" class="custom-control-input" id="showTitle" @change="setHotspot(item)" v-model.trim="item.showTitle" :disabled="item.type=='Video'">
                  <label class="custom-control-label" for="showTitle">显示名称</label>
                </div>
                <div title="跟随缩放" v-if="!item.distorted" class="custom-control custom-switch switch ppitem">
                  <input type="checkbox" class="custom-control-input" id="zoom" @change="setHotspot(item)" v-model.trim="item.zoom">
                  <label class="custom-control-label" for="zoom">跟随缩放</label>
                </div>
              </div>
              <div style="flex:auto">
                <div class="ppitem" title="缩放">
                  <label>缩放</label>
                  <NumberButton class="nb" v-model="item.scale" @change="setHotspot(item)" :defaultValue="0.5" :wheelRatio="0.001" :moveRatio="0.005" step="0.01" min="0.01" max="1" name=""></NumberButton>
                </div>
                <div class="ppitem" title="旋转">
                  <label>旋转</label>
                  <NumberButton class="nb" v-model="item.rotate" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="0.5" step="1" min="-180" max="180" name=""></NumberButton>
                </div>
              </div>
            </div>
            <div v-if="item.distorted" style="display: grid; grid-template-columns: 1fr 1fr 1fr;">
              <div class="ppitem" style="flex:auto" title="X轴">
                <NumberButton class="nb" v-model="item.rx" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="X轴"></NumberButton>
              </div>
              <div class="ppitem" style="flex:auto" title="Y轴">
                <NumberButton class="nb" v-model="item.ry" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="Y轴"></NumberButton>
              </div>
              <div class="ppitem" style="flex:auto" title="Z轴">
                <NumberButton class="nb" v-model="item.rz" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180" name="Z轴"></NumberButton>
              </div>
            </div>
          </template>
          <div v-if="showRange=='chromakey'" style="display:flex">
            <div style="flex:auto">
              <div class="ppitem">
                <label>抠像颜色</label>
                <div class="input-group input-group-sm nb">
                  <input class="form-control" type="color" placeholder="抠像颜色" v-model="item.chromakeyColor" @change="setHotspot(item)" @input="setHotspot(item)" />
                  <div class="input-group-prepend">
                    <span class="input-group-text" @click="getColor(item)"><i class="fa fa-eye-dropper"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div style="flex:auto">
              <div class="ppitem" title="范围">
                <label>范围</label>
                <NumberButton class="nb" v-model="item.threshold" @change="setHotspot(item)" :defaultValue="0.01" :wheelRatio="0.0005" :moveRatio="0.001" step="0.001" :min="0" :max="1" name=""></NumberButton>
              </div>
              <div class="ppitem" title="模糊">
                <label>模糊</label>
                <NumberButton class="nb" v-model="item.smoothing" @change="setHotspot(item)" :defaultValue="0.01" :wheelRatio="0.0005" :moveRatio="0.001" step="0.001" :min="0" :max="1" name=""></NumberButton>
              </div>
            </div>
          </div>
          <div>
            <button v-if="item.type!='Video'" class="btn toorbarBtn" @click="showHotspotIconLibDialog(item.guid)">
              <div><i class="fas fa-icons"></i></div>
              <div>图标</div>
            </button>
            <button v-if="item.type!='Video'" class="btn toorbarBtn" @click="showHotspotLinkDialog(item.guid)">
              <div><i class="fas fa-edit"></i></div>
              <div>编辑</div>
            </button>
            <button v-if="item.type=='Video'" class="btn toorbarBtn" :class="{active:showRange=='chromakey'}" @click="showRange=='chromakey'?showRange=null:showRange='chromakey'">
              <div><i class="fas fa-eye-dropper"></i></div>
              <div>抠像</div>
            </button>
            <button class="btn toorbarBtn" @click="showDeleteHotspot(item.guid)">
              <div><i class="fas fa-trash"></i></div>
              <div>删除</div>
            </button>
          </div>
        </div>
      </template>
    </template>
    <div class="EditToolbar">
      <div class="space"></div>
      <button class="btn toorbarBtn back" style="" @click="$emit('back')">
        <div><i class="fas fa-chevron-left"></i></div>
      </button>
      <div class="overflow-x">
        <button class="btn toorbarBtn" @click="addNewHotspot()">
          <div><i class="fas fa-plus"></i></div>
          <div>热点</div>
        </button>
        <!--<button class="btn toorbarBtn" @click="addNewVideo()">
          <div><i class="fas fa-film"></i></div>
          <div>视频</div>
        </button>-->
        <button v-for="(item,index) in hotspots" class="btn toorbarBtn" :class="{'active':item.guid==editHotspotGuid}" @click="setEditHotspot(item.guid)">
          <div v-if="item.type=='Video'"><HotspotImageView :imageUrl="item.file.url.replace('/0/0/0/0/', '/50/0/0/0/')" bgcolor="#0000" :width="16" :height="16"></HotspotImageView></div>
          <div v-else><HotspotIconView :icon="item.icon" bgcolor="#0000" :width="16" :height="16"></HotspotIconView></div>
          <div>{{item.title||'未命名'}}</div>
        </button>
        <div class="space"></div>
      </div>
    </div>
    <!--<div>
    <ul class="list-unstyled text-shadow HotspotList">
      <li v-for="(item,index) in hotspots" class="hotspotItem" style="pointer-events:auto;width: 52px;" :class="{'active':item.guid==editHotspotGuid}">
        <a @click="setEditHotspot(item.guid)" style="cursor: pointer;">
          <div style="text-align:center;font-size:0;"><HotspotIconView :icon="item.icon" bgcolor="#ccc"></HotspotIconView></div>
          <div class="text-truncate" style="text-align:center;font-size:12px;">
            {{item.title||'未命名'}}
          </div>
        </a>
      </li>
      <li style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;">
        <a @click="addNewHotspot()" style="cursor: pointer;">
          <div style="text-align:center">
            <div class="HotspotIconView" style="width:50px;height:50px;background-color:#ccc;    display: inline-block;">
              <i class="fas fa-plus fa-2x" style="line-height:50px;"></i>
            </div>
          </div>
          <div style="text-align:center;font-size:12px;">
            添加热点
          </div>
        </a>
      </li>
    </ul>
    <div class="PanoHotspotEdit">
      <template v-for="(item,index) in hotspots">
        <template v-if="item.guid==editHotspotGuid">
          <div class="text-shadow" style="pointer-events:none;font-size:12px;">当前热点：{{item.title}}</div>

          <div v-if="showRange=='scale'" class="rangeBar">
            <div class="btn-group btn-group-sm" role="group">
              <button type="button" class="btn btn-default" @click="item.scale=numberMath(item.scale,-0.05,1,0.05).toFixed(2);setHotspot(item)"><i class="fas fa-minus"></i></button>
              <button type="button" class="btn btn-default" @click="item.scale=0.5;setHotspot(item)">复位</button>
              <button type="button" class="btn btn-default" @click="item.scale=numberMath(item.scale,0.05,1,0.05).toFixed(2);setHotspot(item)"><i class="fas fa-plus"></i></button>
            </div>
            <div class="my-2">
              <input type="range" class="custom-range" v-model="item.scale" @change="setHotspot(item)" @dblclick="item.scale=0.5;setHotspot(item)" step="0.01" min="0.05" max="1" />
            </div>
          </div>
          <div v-if="showRange=='rotate'" class="rangeBar">
            <div class="btn-group btn-group-sm" role="group">
              <button type="button" class="btn btn-default" @click="item.rotate=numberMath(item.rotate,-5,180,-180).toFixed(0);setHotspot(item)"><i class="fas fa-minus"></i></button>
              <button type="button" class="btn btn-default" @click="item.rotate=0;setHotspot(item)">复位</button>
              <button type="button" class="btn btn-default" @click="item.rotate=numberMath(item.rotate,5,180,-180).toFixed(0);setHotspot(item)"><i class="fas fa-plus"></i></button>
            </div>
            <div class="my-2">
              <input type="range" class="custom-range" v-model="item.rotate" @change="setHotspot(item)" @dblclick="item.rotate=0;setHotspot(item)" step="1" min="-180" max="180" />
            </div>
          </div>
          <div class="text-right">
            <div class="btn-group btn-group-sm" role="group" style="width:100%">
              <button type="button" class="btn" :class="{'btn-primary':showRange=='scale','btn-default':showRange!='scale'}" @click="showRange=='scale'?showRange=null:showRange='scale'" @dblclick="item.scale=0.5;setHotspot(item)">缩放：{{item.scale}}</button>
              <button type="button" class="btn" :class="{'btn-primary':showRange=='rotate','btn-default':showRange!='rotate'}" @click="showRange=='rotate'?showRange=null:showRange='rotate'" @dblclick="item.rotate=0;setHotspot(item)">旋转：{{item.rotate}}°</button>
              <button type="button" class="btn btn-default" @click="showHotspotIconLibDialog(item.guid)">图标</button>
              <button type="button" class="btn btn-warning" @click="showHotspotLinkDialog(item.guid)">编辑</button>
            </div>
          </div>
          <div class="text-right">
            <div class="btn-group btn-group-sm" role="group">
              <button type="button" class="btn btn-default" @click="item.distorted=!item.distorted;setHotspot(item)">扭曲：{{item.distorted?'是':'否'}}</button>
              <button type="button" class="btn btn-default" @click="item.zoom=!item.zoom;setHotspot(item)">跟随缩放：{{item.zoom?'是':'否'}}</button>
              <button type="button" class="btn btn-default" @click="item.showTitle=!item.showTitle;setHotspot(item)">显示名称：{{item.showTitle?'是':'否'}}</button>
            </div>
          </div>
        </template>
      </template>
    </div>-->
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" backdrop="static" @ready="modelReady" :mStyle="{'z-index':1}">
      <div style="position:relative;">
        <h6>编辑热点链接</h6>
        <div class="" style="position:absolute;top:-5px;right:0;padding:5px;cursor:pointer;" @click="modelHide()">
          <i class="fa fa-times"></i>
        </div>
        <div v-if="linkdata">
          <div class="form-group">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" style="font-weight:700">名称</span>
              </div>
              <input class="form-control" type="text" v-model.trim="linkdata.title" @change="setHotspot(linkdata)" />
            </div>
            <span class="text-danger"></span>
          </div>
          <div class="form-group">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" style="font-weight:700">类型</span>
              </div>
              <select class="form-control" id="Type" v-model:value="linkdata.linkType" @change="linkdata.linkItem=null;linkdata.linkItemGUID=null;">
                <option v-for="i in types" :value="i.value">{{i.key}}</option>
              </select>
            </div>
            <span class="text-danger"></span>
          </div>
          <template v-if="linkdata.linkType=='Pano'">
            <button type="button" class="btn btn-outline-primary mb-1" @click="showPanoLibDialog()">{{linkdata.linkItem?`全景:${linkdata.linkItem.title}`:'选择全景'}}</button>
            <div v-if="linkdata.linkItem">
              <template v-for="item in [linkdata.linkItem]">
                <button type="button" class="btn btn-outline-primary mb-1" @click="SetLinkView()">设置目标全景视角</button>
                <div>
                  <span>视场:<span>{{linkdata.linkFOV}}</span></span>&nbsp;
                  <span>水平:<span>{{linkdata.linkHLookAt}}</span></span>&nbsp;
                  <span>垂直:<span>{{linkdata.linkVLookAt}}</span></span>
                </div>
              </template>
            </div>
          </template>
          <template v-if="linkdata.linkType=='Tour'">
            <button type="button" class="btn btn-outline-primary mb-1" @click="showTourLibDialog()">{{linkdata.linkItem?`漫游:${linkdata.linkItem.title}`:'选择漫游'}}</button>
          </template>
          <div v-if="linkdata.linkType=='File'">
            <button v-if="!linkdata.file" type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
            <div v-if="linkdata.file&&linkdata.file.isImage">
              <div class="card-tools float-right">
                <button type="button" class="btn btn-box-tool" @click="linkdata.file=null" title="删除"><i class="fas fa-trash"></i></button>
              </div>
              <a>
                <div style="height:100px;background-repeat:no-repeat;background-size:contain;background-color:#ccc;background-position:center" :style="{'background-image':'url(' + linkdata.file.url + ')'}"></div>
              </a>
            </div>
            <div v-else-if="linkdata.file&&linkdata.file.isVideo">
              {{linkdata.file.name}}
              <div class="card-tools float-right">
                <button type="button" class="btn btn-box-tool" @click="linkdata.file=null" title="删除"><i class="fas fa-trash"></i></button>
              </div>
              <video style="width:100%;" :src="linkdata.file.url" :poster="((linkdata.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls />
            </div>
            <div v-else-if="linkdata.file&&linkdata.file.isAudio">
              <button type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
            </div>
            <div v-else-if="linkdata.file&&linkdata.file.type=='Coordinate'">
              <button type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
              <div v-if="$refs.model.show" style="width:400px">
                <MapView :geojsonUrl="linkdata.file.url" :dragable="true" height="200px"></MapView>
              </div>
            </div>
            <div v-else-if="linkdata.file">
              <button type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
            </div>
          </div>
          <div v-if="linkdata.linkType!='Pano'&&linkdata.linkType!='Tour'&&linkdata.linkType!='Inn'">
            <label>内容</label>
            <PellEditor v-model.trim="linkdata.description" />
          </div>
          <template v-if="linkdata.linkType=='Link'">
            <label>链接</label>
            <input v-if="$inRole('管理员')||$inRole('VIP')||form.isPro" class="form-control" type="url" v-model.trim="linkdata.url" placeholder="请输入 Url 地址" />
            <input v-else class="form-control" value="需要开通 Pro 功能" disabled />
          </template>
        </div>
        <template v-if="linkdata.linkType=='Inn'">
          <button v-if="linkdata.linkData" type="button" class="btn btn-outline-primary" @click="showInnLibDialog()">客栈<span v-if="linkdata.linkData">：{{linkdata.linkData.title}}</span></button>
          <button v-else type="button" class="btn btn-outline-primary" @click="showInnLibDialog()">选择客栈</button>
        </template>
        <div style="display:flex;justify-content:flex-end;padding: 10px 0 0;">
          <button class="btn btn-default btn-sm mr-2" @click="showHotspotIconLibDialog(linkdata.guid)">图标</button>
          <button class="btn btn-danger btn-sm mr-2" @click="showDeleteHotspot()">删除</button>
          <button type="button" class="btn btn-primary btn-sm" @click="modelHide()">确定</button>
        </div>
      </div>
    </component>
    <SimpleModal ref="deleteHotspot" :zIndex="1040">
      <div>
        <h5>删除热点</h5>
      </div>
      确定删除此热点？
      <div style="display:flex;justify-content:flex-end;">
        <button type="button" class="btn btn-primary btn-sm mr-2" @click="$refs.deleteHotspot.show=false">取消</button>
        <button class="btn btn-danger btn-sm" @click="deleteHotspot(linkdata)">删除</button>
      </div>
    </SimpleModal>
    <HotspotIconLib ref="HotspotIconLib" :libUrl="urls.hotspotIcons()" :onSelected="HotspotIconLibSelected"></HotspotIconLib>
    <InnLib v-if="innUrl" ref="InnLib" :libUrl="innUrl()" :params="params" :onSelected="InnLibSelected"></InnLib>

    <PanoLib ref="PanoLib" :libUrl="urls.libPanosUrl()" :params="params" @submit="hotspotPanoLibSelected"></PanoLib>
    <PanoLib ref="TourLib" :libUrl="urls.libToursUrl()" :categorysUrl="urls.categorys({module:'Tour'})" :params="params" @submit="hotspotPanoLibSelected"></PanoLib>
    <FileLib ref="FileLib" :params="params" :libUrl="urls.fileLib()" :panosUploadUrl="urls.panosUploadUrl()" :filesUploadUrl="urls.filesUploadUrl()" :tagsUrl="urls.privateTags()" :fileUrl="urls.fileUrl()" :panoUrl="urls.panoUrl()" @submit="FileLibSelected"></FileLib>
  </div>
</template>
<script>
  import { uuid } from 'vue-uuid'
  import HotspotIconView from '../../HotspotIconView'
  import HotspotIconLib from '../../HotspotIconLib'
  import PellEditor from '../../PellEditor'
  import InnLib from '../../InnLib'
  import PanoLib from '../../PanoLib'
  import FileLib from '../../FileLib'
  import MapView from '../../Map/MapView'
  import SimpleModal from '../../SimpleModal'
  import NumberButton from '../../NumberButton'

  export default {
    components: {
      HotspotIconView,
      HotspotIconLib,
      PellEditor,
      PanoLib,
      InnLib,
      FileLib,
      MapView,
      SimpleModal,
      NumberButton,
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
        editHotspotGuid: this.publicData.editHotspotGuid || null,
        showRange: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      types() {
        var t = [
          {
            key: '全景',
            value: 'Pano',
          },
          {
            key: '漫游',
            value: 'Tour',
          },
          {
            key: '其他素材',
            value: 'File',
          },
          {
            key: '文字',
            value: 'Text',
          },
          {
            key: '链接',
            value: 'Link',
          },
        ]
        if (this.innUrl) {
          t.push({
            key: '客栈',
            value: 'Inn',
          })
        }
        return t
      },
      innUrl() {
        if (!this.publicData.urls) {
          return null
        }
        return this.publicData.urls.innLib
      },
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      linkdata() {
        for (var i in this.form.hotspots) {
          if (this.form.hotspots[i].guid == this.editHotspotGuid) {
            return this.form.hotspots[i]
          }
        }
        return {}
      },
      hotspots() {
        if (!this.form.hotspots) {
          return []
        }
        return this.form.hotspots.filter((v) => v.type != 'Block')
      },
      form() {
        return this.publicData.pano || { hotspots: [] }
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          setView: {
            target: 'Data',
            name: 'updatePano',
          },
          linkView: {
            name: 'renewSence',
            arg: 'linkView'
          },
          tweenView: {
            target: 'FullPano',
            name: 'tweenView'
          },
        },
        funcs: {
          setEditHotspot: this.setEditHotspotGuid
        }
      })
      //if (this.editHotspotGuid) {
      //  var h = this.form.hotspots[this.getHotspotIndex(this.editHotspotGuid)]
      //  this.getHotspot(h)
      //}
    },
    destroyed() {
    },
    methods: {
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
        if (this.publicData.linkView) {
          this.modelShow()
          this.getFunc('changePublicData')({
            linkView: false
          })
        }
      },
      setEditHotspotGuid(item) {
        this.setEditHotspot(item.guid)
      },
      setEditHotspot(id) {
        if (this.editHotspotGuid == id) {
          var h = this.pano.hotspots[this.getHotspotIndex(id)]
          if (h.type == 'Video') {
            return
          }
          this.showHotspotLinkDialog(id)
        } else {
          this.showRange = null
          this.editHotspotGuid = id;
          var h = this.form.hotspots[this.getHotspotIndex(id)]
          this.getHotspot(h)
        }
      },
      getHotspot(h) {
        var view = {
          hlookat: h.ath,
          vlookat: h.atv,
        }
        this.$emit('tweenView', view)
      },
      setHotspot(hotspot) {
        this.getFunc({ target: 'FullPano', name: 'setHotspot' })(hotspot)
      },
      showHotspotLinkDialog(guid) {
        this.editHotspotGuid = guid;
        this.modelShow()
      },
      showPanoLibDialog() {
        this.$refs.PanoLib.showDialog();
      },
      showTourLibDialog() {
        this.$refs.TourLib.showDialog();
      },
      hotspotPanoLibSelected(select) {
        if (!this.linkdata) {
          this.$message({
            message: '程序错误',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        this.linkdata.linkItemGUID = select.guid;
        this.linkdata.linkItem = select;
        if (this.linkdata.title == '' || this.linkdata.title == null) {
          this.linkdata.title = select.title
          this.setHotspot(this.linkdata)
        }
        console.log(select)
        this.$refs.PanoLib.show = false;
      },
      showFileLibDialog() {
        this.$refs.FileLib.showDialog();
      },
      FileLibSelected(select) {
        if (select.guid == this.panoId) {
          this.$message({
            message: '您不能选择自身全景',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        if (!this.linkdata) {
          this.$message({
            message: '程序错误',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        this.linkdata.file = select;
        if (this.linkdata.title == '' || this.linkdata.title == null) {
          this.linkdata.title = select.name
          this.setHotspot(this.linkdata)
        }
      },
      showInnLibDialog() {
        this.$refs.InnLib.showDialog();
      },
      InnLibSelected(select) {
        if (!this.linkdata) {
          this.$message({
            message: '程序错误',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        this.linkdata.linkData = { guid: select.id, title: select.title };
        if (this.linkdata.title == '' || this.linkdata.title == null) {
          this.linkdata.title = select.title
          this.setHotspot(this.linkdata)
        }
        console.log(select)
        this.$refs.PanoLib.show = false;
      },
      showDeleteHotspot(guid) {
        if (guid) {
          this.editHotspotGuid = guid
        }
        this.$refs.deleteHotspot.show = true
      },
      deleteHotspot(hotspot) {
        var index = -1
        for (var i in this.form.hotspots) {
          if (this.form.hotspots[i].guid == hotspot.guid) {
            index = i;
          }
        }
        if (index < 0) {
          return;
        }
        this.getFunc({ target: 'FullPano', name: 'removeHotspot' })(hotspot)
        this.form.hotspots.splice(index, 1);

        this.$refs.deleteHotspot.show = false
        this.modelHide()
      },
      SetLinkView() {
        var item = this.linkdata.linkItem
        item.fov = this.linkdata.linkFOV
        item.hLookAt = this.linkdata.linkHLookAt
        item.vLookAt = this.linkdata.linkVLookAt
        var v = this.getFunc({ target: 'FullPano', name: 'getView' })()
        this.modelHide()
        this.getFunc('changePublicData')({
          editHotspotGuid: this.editHotspotGuid, lastView: {
            fov: v.fov,
            vlookat: v.vlookat,
            hlookat: v.hlookat
          }
        })
        this.$emit('setView', item)
        this.$emit('linkView')
      },
      showHotspotIconLibDialog(guid) {
        this.editHotspotGuid = guid;
        this.$refs.HotspotIconLib.showDialog();
      },
      HotspotIconLibSelected(select) {
        var index = this.getHotspotIndex(this.editHotspotGuid)
        this.form.hotspots[index].icon = select;
        this.setHotspot(this.form.hotspots[index])
      },
      getHotspotIndex(guid) {
        for (var i in this.form.hotspots) {
          if (this.form.hotspots[i].guid == guid) {
            return i
          }
        }
        return null
      },
      addNewHotspot() {
        this.$refs.HotspotIconLib.showDialog((select) => {
          var hotspot = this.newHotspot()
          hotspot.icon = select
          this.setHotspot(hotspot)
          this.form.hotspots.push(hotspot)
          this.editHotspotGuid = hotspot.guid
          this.showHotspotLinkDialog(this.editHotspotGuid)
        });
      },
      newHotspot(setting) {
        var guid = uuid.v1();
        var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
        return {
          addTime: '',
          ath: view.hlookat || 0,
          atv: view.vlookat || 0,
          description: "",
          distorted: false,
          file: null,
          gpsLat: 0,
          gpsLng: 0,
          guid: guid,
          icon: {
            guid: null,
            width: 0,
            height: 0,
            fps: 0,
            isAnimation: false,
            edge: 'top',
            imageUrl: '%SWFPATH%/skin/vtourskin_hotspot.png',
            //enableText: true,
            textOffsetX: 0,
            textOffsetY: 0,
            textEdge: 'bottom'
          },
          linkFOV: 120,
          linkHLookAt: 0,
          linkItemGUID: null,
          linkType: 'Pano',
          linkVLookAt: 0,
          points: Array[0],
          rotate: 0,
          scale: 0.5,
          rz: 0,
          rx: 0,
          ry: 0,
          loop: false,
          inheritView: false,
          showTitle: true,
          sortID: 2,
          title: "",
          type: 'Image',
          url: null,
          zoom: false,
          ...setting
        }
      },
      numberMath(val, step, max, min) {
        val = Number(val)
        step = Number(step)
        val += step
        if (max && val > max) {
          val = max;
        }
        if (min && val < min) {
          val = min;
        }
        return Number(val);
      },
    },
  }
</script>
<style scoped>
  label {
    color: white;
  }

  .baseArea {
    background-color: #0008;
    opacity: 1;
    display: block;
    padding: 5px;
  }

  .ppitem {
    margin: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
  }

    .ppitem label {
      margin-right: 5px;
      flex: none;
    }

    .ppitem .nb {
      flex: auto;
      max-width: 150px;
    }

  /*.HotspotList {
    position: absolute;
    top: 0;
    left: 0;
    right: 2.5rem;
    overflow: auto;
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
    opacity: 0.75;
    pointer-events: none;
  }

  .hotspotItem {
    display: inline-block;
    margin: 5px;*/
  /*width: 4.5em;*/
  /*vertical-align: top;
  }

    .hotspotItem.active {
      margin: 4px;
      border: 1px solid #fff;
      box-shadow: #000 0 0 2px 0;
    }

    .hotspotItem .badge {
      position: absolute;
      right: 2px;
    }

  .PanoHotspotEdit {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none !important;
    margin: 5px;
    opacity: 0.75;
  }

    .PanoHotspotEdit > * {
      pointer-events: auto;
      margin: 5px 0;
    }

  .rangeBar {
    background-color: #fff5;
    padding: 5px;
    margin: 0px 0 -5px;
    text-align: center;
    border-radius: 0.2rem;
  }*/
</style>
